import { withMountWhenInView } from '@studiometa/js-toolkit';
import { Transition, loadImage } from '@studiometa/ui';

/**
 * Lazy
 */
export default class Lazy extends withMountWhenInView(Transition, {
  threshold: [0, 1],
}) {
  static config = {
    ...Transition.config,
    name: 'Lazy',
    emits: ['load'],
  };

  /**
   * Type of element
   * @returns {string}
   */
  get type() {
    return this.$el.tagName.toLowerCase();
  }

  /**
   * Set the image source.
   */
  set src(value) {
    this.$el.src = value;
  }

  /**
   * Get the original source.
   * @returns {string}
   */
  get original() {
    return this.$el.dataset.src;
  }

  /**
   * Mounted
   */
  async mounted() {
    if (this.$el instanceof HTMLImageElement) {
      this.$log('image');
      const src = this.original;
      await loadImage(src);
      this.src = src;
      this.enter();
      this.$emit('load');
    }

    if (this.$el instanceof HTMLVideoElement) {
      this.$log('video');
      const src = this.original;
      this.src = src;
      this.$emit('load');
    }

    this.$destroy();
  }

  /**
   * Terminate the component on load.
   */
  onLoad() {
    this.$terminate();
  }
}
