import { Base, createApp } from '@studiometa/js-toolkit';
import { Modal, LargeText, ScrollAnimation, AnchorScrollTo } from '@studiometa/ui';
import Lazy from './atoms/Lazy.js';

/**
 * @typedef {{
 *   $children: {
 *     Lazy: Lazy[],
 *     Modal: Modal[],
 *     LargeText: LargeText[],
 *     ScrollAnimation: ScrollAnimation[],
 *     'a[href^=#]': AnchorScrollTo[],
 *   }
 * }} AppProps
 */

/**
 * App class.
 * @extends {Base<AppProps>}
 */
class App extends Base {
  /**
   * Config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'App',
    components: {
      Lazy,
      Modal,
      LargeText,
      ScrollAnimation,
      'a[href^="#"]': AnchorScrollTo,
    },
  };
}

export default createApp(App, {
  features: {
    asyncChildren: true,
  },
});
